import './integrationcard.css';
import { IntegrationResponse } from '../../../api/petcloudapi/api';
import { ReactComponent as IconShopify } from '../../../../assets/icon/integrations/shopify.svg';
import { ReactComponent as IconShopware } from '../../../../assets/icon/integrations/shopware.svg';
import { ReactComponent as IconMagento } from '../../../../assets/icon/integrations/magento.svg';
import { ReactComponent as IconLink } from '../../../../assets/icon/link.svg';
import Card, { CardSection } from '../../../elements/card/Card';
import Button from '../../../elements/button/Button';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IconSettings } from '../../../../assets/icon/settings.svg';
import useIntegrationBadges from './hooks/useIntegrationBadges';
import { useTranslation } from 'react-i18next';
import InpettoClientBasedSetup from '../setup/inpettoclientbased/InpettoClientBasedSetup';
import { useUser } from '../../../contexts/auth/User';

interface IntegrationCardProps {
  integration: IntegrationResponse;
  manufacturerId?: string | null;
}

const IntegrationCard: React.FC<IntegrationCardProps> = ({
  integration,
  manufacturerId,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integrations',
  });
  const link = useNavigate();
  const { getBadges } = useIntegrationBadges(integration);
  const { user } = useUser();

  const installationUrl = integration.config?.config
    ? JSON.parse(integration.config?.config).InstallationLink
    : null;

  console.log(installationUrl);

  const mId = manufacturerId ?? user?.manufacturerId;

  return (
    <Card bigScreenWidth="33.33%" noMargin>
      <CardSection title={integration.name} badges={getBadges()}>
        <div className={'integrationCard'}>
          {integrationIcon[integration.identifier]}
          {!!integration.config && integration.config.installed ? (
            <Button
              type={'icon'}
              width={'minimal'}
              look={'secondary'}
              helperCSSClass={'integrationCard-button'}
              action={() =>
                link(
                  `/integration/${integration.identifier.toLowerCase()}/${
                    integration.id
                  }${mId ? '/' + mId : ''}`
                )
              }
              active={!!integration.config}
            >
              <IconSettings className={'button-icon button-icon-tertiary'} />
            </Button>
          ) : integration.identifier === 'Shopify' ? (
            <Button
              cta={t('install')}
              width={'minimal'}
              look={'secondary'}
              type={'icon-text'}
              action={() => window.open(installationUrl)?.focus()}
              active={!!installationUrl}
              inactiveMessage={t('contactToInstall')}
            >
              <IconLink className={'button-icon button-icon-secondary'} />
            </Button>
          ) : (
            <InpettoClientBasedSetup
              integration={integration}
              manufacturerId={manufacturerId}
            />
          )}
        </div>
      </CardSection>
    </Card>
  );
};

export default IntegrationCard;

const integrationIcon: { [key: string]: JSX.Element } = {
  Shopify: <IconShopify className={'integrationCard-icon'} />,
  Shopware: <IconShopware className={'integrationCard-icon'} />,
  Magento: <IconMagento className={'integrationCard-icon'} />,
};
