import ReactDatePicker from 'react-datepicker';
import './datepicker.css';
import { ReactComponent as Calendar } from '../../../assets/icon/calendar.svg';
import { SmallHint } from '../hint/Hint';
import 'react-datepicker/dist/react-datepicker.css';

interface DatePickerProps {
  selected: Date | null;
  onChange: (date: Date | null) => void;
  title?: string;
  dateFormat?: string;
  hint?: string;
  showTimeSelect?: boolean;
  showMonthYearPicker?: boolean;
  maxDate?: Date;
}

const DatePicker: React.FC<DatePickerProps> = ({
  selected,
  onChange,
  title,
  dateFormat,
  hint,
  showTimeSelect,
  showMonthYearPicker,
  maxDate,
}) => {
  return (
    <div className="datepicker">
      <div className="datepicker-header">
        {title ? <div className="datepicker-title">{title}</div> : null}
        {hint ? <SmallHint paragraphs={[hint]} /> : null}
      </div>
      <div className="datepicker-picker">
        {/*@ts-ignore*/}
        <ReactDatePicker
          selected={selected}
          onChange={onChange}
          wrapperClassName={'datepicker-picker-wrapper'}
          dateFormat={dateFormat}
          popperClassName={'datepicker-popper'}
          showMonthYearPicker={showMonthYearPicker}
          maxDate={maxDate}
        />
        <div className="input-unit datepicker-picker-unit">
          <Calendar
            className="input-unit-icon"
            fill="var(--color-text_secondary)"
          />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
