import { useTranslation } from 'react-i18next';
import { Stage, Navigator } from '../../layout/stage/Stage';
import Card, { CardSection, InlineCards } from '../../elements/card/Card';
import AreaChart from '../../sections/widgets/common/charts/areachart/AreaChart';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import DateRange from '../../elements/daterange/DateRange';
import {
  SimpleOrderResponse,
  UnfulfilledOrdersResponse,
} from '../../api/petcloudapi/api';
import { LoadingContainer } from '../../elements/loading/Loading';
import { DropdownOption } from '../../elements/selectors/Selectors';
import { useUser } from '../../contexts/auth/User';
import { useNavigate } from 'react-router-dom';
import { Has, useAuthorization } from '../../contexts/auth/Authorization';
import ProductTour from '../../sections/widgets/producttour/ProductTour';
import SalesOverview from '../../sections/widgets/salesoverview/SalesOverview';
import OrderList from '../../features/orderlist/OrderList';
import OrderStateOverview from '../../sections/widgets/orderstateoverview/OrderStateOverview';
import useDateTools from '../../hooks/useDateTools';
import DailyBestSellers from '../../sections/widgets/dailybestsellers/DailyBestSellers';
import Bestsellers from '../../sections/widgets/bestsellers/Bestsellers';
import RecurringCustomers from '../../sections/widgets/recurringcustomers/RecurringCustomers';
import DropdownMenu from '../../elements/dropdownmenu/DropdownMenu';
import RevenueData from './types/RevenueData';
import useNumberFormat from '../../hooks/useNumberFormat';

const Dashboard = () => {
  const { t } = useTranslation('translations', { keyPrefix: 'view.dashboard' });
  const { user } = useUser();
  const { authorizations } = useAuthorization();
  const link = useNavigate();
  const api = usePetCloudApi();
  const dashboardsApi = api.dashboardsApi();
  const manufacturersApi = api.manufacturersApi();
  const ordersApi = api.ordersApi();
  const errorHandler = useErrorHandler();
  const {
    dateToCSharpDateTimeOffset,
    getDateOnlyFromDate,
    displayReadableMinifiedDate,
  } = useDateTools();
  const { renderCurrency } = useNumberFormat('EUR');

  const getDefaultDateRange = () => {
    const date = new Date();
    const today = getDateOnlyFromDate(date) ?? 'error';
    const beginningOfMonth =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1).toString().padStart(2, '0') +
      '-01';
    return [beginningOfMonth, today];
  };
  const [selectedDateRange, setSelectedDateRange] = useState(
    getDefaultDateRange()
  );
  const [revenueData, setRevenueData] = useState<RevenueData[] | null>(null);
  const [manufacturers, setManufacturers] = useState<DropdownOption[] | null>(
    null
  );
  const [selectedManufacturer, setSelectedManufacturer] =
    useState<DropdownOption | null>(null);
  const [orders, setOrders] = useState<SimpleOrderResponse[] | null>(null);
  const [unfulfilledOrders, setUnfulfilledOrders] = useState<
    UnfulfilledOrdersResponse[] | null
  >(null);

  useEffect(() => {
    if (!user?.manufacturerId) {
      getManufacturers();
    }
    getUnfulfilledOrders();
  }, []);

  const getManufacturerId = () => {
    const userManufacturerId = user?.manufacturerId;
    if (userManufacturerId) {
      return userManufacturerId;
    }
    const selectedManufacturerId = selectedManufacturer?.id;
    if (selectedManufacturerId) {
      return selectedManufacturerId !== 'all'
        ? selectedManufacturerId
        : undefined;
    }
  };

  useEffect(() => {
    const mId = getManufacturerId();
    const from = dateToCSharpDateTimeOffset(
      new Date(selectedDateRange[0]),
      true
    );
    const to = dateToCSharpDateTimeOffset(new Date(selectedDateRange[1]));

    getRevenueData(selectedDateRange[0], selectedDateRange[1], mId);
    getOrders(from, to, mId);
  }, [selectedManufacturer, selectedDateRange]);

  const getUnfulfilledOrders = () => {
    dashboardsApi
      .dashboardsGetUnfulfilledOrders()
      .then((response) => {
        console.log(response);
        setUnfulfilledOrders(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getManufacturers = () => {
    manufacturersApi
      .manufacturersGetSimpleManufacturers()
      .then((response) => {
        console.log(response);
        const options = response.data.map((manufacturer) => {
          return {
            id: manufacturer.id,
            name: manufacturer.companyName,
          };
        });
        if (!user?.manufacturerId) {
          options.unshift({
            id: 'all',
            name: t('allManufacturers'),
          });
        }
        setManufacturers(options);
        setSelectedManufacturer(options[0]);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  // fetched revenue data and refunds data and puts them into a custom object
  // so that they can be rendered in the same area chart
  const getRevenueData = (
    from: string,
    to: string,
    manufacturerId: string | undefined
  ) => {
    dashboardsApi
      .dashboardsGetDailyRevenue(manufacturerId, from, to)
      .then((response) => {
        console.log(response);

        const revenue = injectMissingDays(
          response.data,
          {
            manufacturerId: null,
            totalRevenueNet: 0,
            totalRevenue: 0,
            totalSuccessOrders: 0,
            totalFailedOrders: 0,
            totalRefundAmount: 0,
            totalNumberOfRefunds: 0,
          },
          new Date(from),
          new Date(to)
        );
        dashboardsApi
          .dashboardsGetDailyRefunds(manufacturerId, from, to)
          .then((response) => {
            console.log(response);
            const result: RevenueData[] = revenue.map((rev) => {
              const refunds = response.data.find((x) => x.date === rev.date);
              return {
                ...rev,
                totalRefundAmount: refunds?.totalRefundAmount
                  ? refunds.totalRefundAmount * -1
                  : 0,
                totalNumberOfRefunds: refunds?.totalNumberOfRefunds ?? 0,
              };
            });
            setRevenueData(result);
          })
          .catch((error) => {
            console.log(error);
            errorHandler.addError(error.response);
          });
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getOrders = (from?: string, to?: string, manufacturerId?: string) => {
    setOrders(null);
    ordersApi
      .ordersGetSimpleOrdersList(undefined, from, to)
      .then((response) => {
        console.log(response);
        if (manufacturerId) {
          setOrders(
            response.data.filter(
              (order) => order.manufacturer.id === manufacturerId
            )
          );
        } else {
          setOrders(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const xAxisFormatter = (value: any, index: number) => {
    return displayReadableMinifiedDate(value);
  };

  const revenueToolTipFormatter = (value: any, name: string, props: any) => {
    return [renderCurrency(value), t(`dailyRevenues.${name}`)];
  };

  const ordersToolTipFormatter = (value: any, name: string, props: any) => {
    return [value, t(`dailyOrders.${name}`)];
  };

  const toolTipLabelFormatter = (value: string) => {
    return displayReadableMinifiedDate(value, true, undefined, true);
  };

  if (authorizations) {
    return (
      <Has authorizations={['dashboard:list']}>
        {revenueData && orders && unfulfilledOrders ? (
          <Stage>
            <Navigator title={t('title')}>
              {manufacturers && selectedManufacturer ? (
                <div style={{ marginRight: 10 }}>
                  <DropdownMenu
                    optionObjects={manufacturers}
                    selected={selectedManufacturer.name}
                    onSelect={(value, id) => {
                      if (id) {
                        const selected = manufacturers.find((m) => m.id === id);
                        if (selected) {
                          setSelectedManufacturer(selected);
                        }
                      }
                    }}
                  />
                </div>
              ) : null}
              <DateRange
                selectedDateRange={selectedDateRange}
                update={(range) => {
                  setSelectedDateRange(range);
                }}
              />
            </Navigator>
            <ProductTour
              title={t('productTour.title')}
              text={t('productTour.text')}
              settingName={'productTour_welcome'}
              infoItems={[
                {
                  title: t('knowledgebase.product.tutorials.new.title'),
                  text: t('knowledgebase.product.tutorials.new.text'),
                  videoUri: t('knowledgebase.product.tutorials.new.video'),
                },
                {
                  title: t('knowledgebase.settings.team.addMember.title'),
                  text: t('knowledgebase.settings.team.addMember.text'),
                  videoUri: t('knowledgebase.settings.team.addMember.media'),
                },
                {
                  title: t('knowledgebase.product.tutorials.ready.title'),
                  text: t('knowledgebase.product.tutorials.ready.text'),
                  videoUri: t('knowledgebase.product.tutorials.ready.video'),
                },
              ]}
            />
            <InlineCards>
              <Card
                bigScreenWidth="50%"
                title={t('salesOverview.revenue.title')}
              >
                <CardSection>
                  <SalesOverview
                    mode={'revenue'}
                    revenueData={revenueData}
                    selectedManufacturerId={selectedManufacturer?.id}
                    showPrognosis={
                      JSON.stringify(selectedDateRange) ===
                      JSON.stringify(getDefaultDateRange())
                    }
                  />
                </CardSection>
              </Card>
              <Card
                bigScreenWidth="50%"
                title={t('salesOverview.orders.title')}
              >
                <CardSection>
                  <SalesOverview
                    mode={'orders'}
                    revenueData={revenueData}
                    selectedManufacturerId={selectedManufacturer?.id}
                  />
                </CardSection>
              </Card>
            </InlineCards>
            <InlineCards>
              <Card bigScreenWidth="50%" title={t('dailyRevenues.title')}>
                <CardSection>
                  <AreaChart
                    data={revenueData}
                    areas={[
                      {
                        dataKey: 'totalRefundAmount',
                        strokeColor: 'var(--color-inherited)',
                        fillColor: 'rgb(142, 130, 239, 0.3)',
                        legendName: t('dailyRevenues.totalRefundAmount'),
                      },
                      {
                        dataKey: 'totalRevenue',
                        legendName: t('dailyRevenues.totalRevenue'),
                      },
                    ]}
                    yAxis={{ dataKey: 'totalRevenue', steps: 3 }}
                    xAxis={{ dataKey: 'date', formatter: xAxisFormatter }}
                    height={220}
                    toolTipFormatter={revenueToolTipFormatter}
                    toolTipLabelFormatter={toolTipLabelFormatter}
                  />
                </CardSection>
              </Card>
              <Card bigScreenWidth="50%" title={t('dailyOrders.title')}>
                <CardSection>
                  <AreaChart
                    data={
                      user?.isProductOwner
                        ? revenueData
                        : revenueData.map(
                            ({ totalFailedOrders, ...rest }) => rest
                          )
                    }
                    areas={[
                      {
                        dataKey: 'totalFailedOrders',
                        strokeColor: 'var(--color-danger)',
                        fillColor: 'rgba(222,53,3,0.3)',
                        legendName: t('dailyOrders.totalFailedOrders'),
                      },
                      {
                        dataKey: 'totalNumberOfRefunds',
                        strokeColor: 'var(--color-inherited)',
                        fillColor: 'rgb(142, 130, 239, 0.3)',
                        legendName: t('dailyOrders.totalNumberOfRefunds'),
                      },
                      {
                        dataKey: 'totalSuccessOrders',
                        legendName: t('dailyOrders.totalSuccessOrders'),
                      },
                    ]}
                    yAxis={{ dataKey: 'totalSuccessOrders', steps: 3 }}
                    xAxis={{ dataKey: 'date', formatter: xAxisFormatter }}
                    height={220}
                    toolTipFormatter={ordersToolTipFormatter}
                    toolTipLabelFormatter={toolTipLabelFormatter}
                  />
                </CardSection>
              </Card>
            </InlineCards>
            <InlineCards>
              <Card bigScreenWidth="100%" title={t('orderOverview')}>
                <CardSection>
                  <OrderStateOverview
                    orders={orders}
                    unfulfilledOrders={unfulfilledOrders}
                  />
                </CardSection>
              </Card>
            </InlineCards>
            <InlineCards>
              <Card bigScreenWidth={'100%'} title={t('bestsellers')}>
                <CardSection>
                  <Bestsellers
                    dateRangeFrom={selectedDateRange[0]}
                    dateRangeTo={selectedDateRange[1]}
                    selectedManufacturerId={selectedManufacturer?.id}
                  />
                </CardSection>
              </Card>
            </InlineCards>
            <InlineCards>
              <Card bigScreenWidth={'100%'} title={t('dailyBestsellers')}>
                <CardSection>
                  <DailyBestSellers
                    dateRangeFrom={selectedDateRange[0]}
                    dateRangeTo={selectedDateRange[1]}
                    selectedManufacturerId={selectedManufacturer?.id}
                  />
                </CardSection>
              </Card>
            </InlineCards>
            <InlineCards>
              <Card bigScreenWidth={'100%'} title={t('recurringCustomers')}>
                <CardSection>
                  <RecurringCustomers
                    dateRangeFrom={selectedDateRange[0]}
                    dateRangeTo={selectedDateRange[1]}
                    selectedManufacturerId={selectedManufacturer?.id}
                  />
                </CardSection>
              </Card>
            </InlineCards>
            <InlineCards>
              <Card
                bigScreenWidth="100%"
                title={t('recentOrders')}
                cta={t('allOrders')}
                noMargin
                action={() => link('/orders')}
              >
                <CardSection>
                  <OrderList
                    ordersArray={orders}
                    unfulfilledOrdersArray={unfulfilledOrders}
                    height={600}
                  />
                </CardSection>
              </Card>
            </InlineCards>
          </Stage>
        ) : (
          <LoadingContainer />
        )}
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Dashboard;

export const injectMissingDays = (
  dailyData: any & { date: string },
  emptyDailyObject: any,
  startDate: Date,
  endDate: Date
) => {
  console.log(startDate);
  console.log(endDate);

  const allDates = getAllDatesBetween(startDate, endDate);
  return allDates.map((date) => {
    const result = dailyData.find((d: any) => d.date === date);
    if (result) {
      return result;
    } else {
      return { ...emptyDailyObject, date: date };
    }
  });
};

export const getAllDatesBetween = (startDate: Date, endDate: Date) => {
  const result: string[] = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    result.push(
      `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`
    );
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }
  console.log(result);
  return result;
};
