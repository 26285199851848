import { useTranslation } from 'react-i18next';
import moment from 'moment';

const useDateTools = () => {
  const { t } = useTranslation();

  const displayReadableDate = (dateString: string) => {
    const date = new Date(Date.parse(dateString));
    return `${date.getDate()}. ${t(
      `components.datetime.months.${date.getMonth()}`
    )} ${date.getFullYear()} | ${date.getHours()}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  };

  const displayReadableMinifiedDate = (
    dateString: string,
    withYear?: boolean,
    divider?: string,
    monthName?: boolean
  ) => {
    const date = new Date(Date.parse(dateString));
    const div = divider ?? '.';
    return `${date.getDate().toString().padStart(2, '0')}${div}${
      !monthName
        ? date.getMonth() + 1
        : t(`components.datetime.months.${date.getMonth()}`)
            .toString()
            .padStart(2, '0')
    }${withYear ? (!monthName ? div : ' ') + date.getFullYear() : ''}`;
  };

  const getDateOnlyFromDate = (date: Date | null | undefined) => {
    if (date) {
      const yyyy = date.getFullYear();
      const mm = date.getMonth() + 1;
      const dd = date.getDate();

      return `${yyyy}-${mm.toString().padStart(2, `0`)}-${dd
        .toString()
        .padStart(2, `0`)}`;
    } else {
      return null;
    }
  };

  const getDateOnlyFromString = (iso8601String: string) => {
    return iso8601String.split('T')[0];
  };

  const dateToISOStringAtMidnight = (date: Date | null | undefined) => {
    if (date) {
      return `${getDateOnlyFromDate(date)}T00:00:00.000`;
    } else {
      return null;
    }
  };

  const getTimeZoneOffset = () => {
    const date = moment.utc().local();
    return `+${date.toISOString(true).split('+')[1]}`;
  };

  const appendTimeZoneOffset = (iso8601String: string) => {
    return iso8601String + getTimeZoneOffset();
  };

  const dateToCSharpDateTimeOffset = (date: Date, setToMidnight?: boolean) => {
    return `${getDateOnlyFromDate(date)}${
      setToMidnight ? 'T00:00:00.00000' : 'T23:59:59.99999'
    }${getTimeZoneOffset()}`;
  };

  const getDayCountOfCurrentMonth = () => {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  return {
    displayReadableDate,
    displayReadableMinifiedDate,
    getDateOnlyFromDate,
    getDateOnlyFromString,
    dateToISOStringAtMidnight,
    getTimeZoneOffset,
    appendTimeZoneOffset,
    dateToCSharpDateTimeOffset,
    getDayCountOfCurrentMonth,
  };
};

export default useDateTools;
