import './categoryedit.css';
import {
  AnimalSpeciesResponse,
  CreateProductCategoryRequest,
  ProductGroupResponse,
  UpdateProductCategoryRequest,
} from '../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import Input from '../../../elements/input/Input';
import ToggleSwitch from '../../../elements/toggleswitch/ToggleSwitch';
import AnimalSpeciesArray from '../../../sections/admin/animalspecies/animalspeciesarray/AnimalSpeciesArray';
import ProductGroupsArray from '../../../sections/admin/productgroups/productgrouparray/ProductGroupsArray';

interface CategoryEditProps {
  category: CreateProductCategoryRequest | UpdateProductCategoryRequest;
  setCategory: (
    category: CreateProductCategoryRequest | UpdateProductCategoryRequest
  ) => void;
  productGroups: ProductGroupResponse[] | null | undefined;
  availableProductGroups: ProductGroupResponse[];
  animalSpecies: AnimalSpeciesResponse[] | null | undefined;
  availableAnimalSpecies: AnimalSpeciesResponse[];
  isLeafCategory?: boolean;
}

const CategoryEdit: React.FC<CategoryEditProps> = ({
  category,
  setCategory,
  productGroups,
  availableProductGroups,
  animalSpecies,
  availableAnimalSpecies,
  isLeafCategory,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.categoryHierarchy.form',
  });

  return (
    <div className="categoryEdit">
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <ToggleSwitch
            label={t('enabled')}
            toggled={category.enabled}
            toggle={() => {
              setCategory({
                ...category,
                enabled: !category.enabled,
              });
            }}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <ToggleSwitch
            label={t('isDisabledForProducts')}
            toggled={category.isDisabledForProducts}
            toggle={() => {
              setCategory({
                ...category,
                isDisabledForProducts: !category.isDisabledForProducts,
              });
            }}
            toggledColor={'var(--color-danger)'}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('nameDE')}
            content={category.name['de-DE']}
            update={(value) => {
              setCategory({
                ...category,
                name: {
                  ...category.name,
                  'de-DE': value,
                },
              });
            }}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('nameEN')}
            content={category.name['en-GB']}
            update={(value) => {
              setCategory({
                ...category,
                name: {
                  ...category.name,
                  'en-GB': value,
                },
              });
            }}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('identifier')}
            content={category.identifier}
            update={(value) => {
              setCategory({
                ...category,
                identifier: value,
              });
            }}
            required
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('descriptionDE')}
            content={
              category.description ? category.description['de-DE'] : null
            }
            update={(value) => {
              setCategory({
                ...category,
                description: {
                  ...category.description,
                  'de-DE': value,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('descriptionEN')}
            content={
              category.description ? category.description['en-GB'] : null
            }
            update={(value) => {
              setCategory({
                ...category,
                description: {
                  ...category.description,
                  'en-GB': value,
                },
              });
            }}
          />
        </div>
      </div>
      <ProductGroupsArray
        hint={
          !isLeafCategory
            ? {
                title: t('productGroupsHint.title'),
                paragraphs: [t('productGroupsHint.text')],
              }
            : undefined
        }
        productGroups={productGroups}
        availableProductGroups={availableProductGroups}
        onDelete={(index) => {
          let update = category.productGroupIds;
          if (update) {
            update.splice(index, 1);
          }
          setCategory({
            ...category,
            productGroupIds: update,
          });
        }}
        onSubmit={
          isLeafCategory
            ? (productGroupIds) => {
                setCategory({
                  ...category,
                  productGroupIds: productGroupIds,
                });
              }
            : undefined
        }
      />
      <AnimalSpeciesArray
        hint={
          !isLeafCategory
            ? {
                title: t('animalSpeciesHint.title'),
                paragraphs: [t('animalSpeciesHint.text')],
              }
            : undefined
        }
        animalSpecies={animalSpecies}
        availableAnimalSpecies={availableAnimalSpecies}
        onDelete={(index) => {
          let update = category.animalSpeciesIds;
          if (update) {
            update.splice(index, 1);
          }
          setCategory({
            ...category,
            animalSpeciesIds: update,
          });
        }}
        onSubmit={
          isLeafCategory
            ? (animalSpecies) => {
                let update = category.animalSpeciesIds;
                if (update) {
                  update.push(animalSpecies.id);
                } else {
                  update = [animalSpecies.id];
                }
                setCategory({
                  ...category,
                  animalSpeciesIds: update,
                });
              }
            : undefined
        }
      />
    </div>
  );
};

export default CategoryEdit;
