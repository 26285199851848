import { useState } from 'react';
import { ListFilterProps } from '../ListFilter';
import useDateTools from '../../../../../hooks/useDateTools';
import useUrlParams from '../../../../../hooks/useUrlParams';
import { useTranslation } from 'react-i18next';

type DateRangeFilterOptions = {
  showTimeSelect?: boolean;
};

const useDateRangeListFilter = (opts?: DateRangeFilterOptions) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.useListFilters',
  });
  const { getDateOnlyFromDate } = useDateTools();
  const { setUrlParam, removeUrlParam, getUrlParam } = useUrlParams();

  const getDateRangeFromURLParams = () => {
    const dateRangeParam = getUrlParam('dateRange');
    if (dateRangeParam && dateRangeParam !== '') {
      return dateRangeParam.split(',');
    } else {
      return null;
    }
  };

  const [selectedDateRange, setSelectedDateRange] = useState<string[] | null>(
    getDateRangeFromURLParams()
  );

  const updateDateRange = (range: string[]) => {
    setSelectedDateRange(range);
    console.log(range.toString());
    setUrlParam('dateRange', range.toString());
  };

  const dateRangeOptions = [
    {
      name: t('dateRangeOptions.allTime'),
      id: 'allTime',
    },
    { name: t('dateRangeOptions.dateRange'), id: 'dateRange' },
  ];

  const dateRangeOptionFilter: ListFilterProps = {
    optionObjects: dateRangeOptions,
    update: (option) => {
      const mode = option.id;
      if (mode) {
        if (mode === 'allTime') {
          setSelectedDateRange(null);
          removeUrlParam('dateRange');
        } else {
          const from = getDateOnlyFromDate(new Date());
          const to = getDateOnlyFromDate(new Date());
          if (from && to) setSelectedDateRange([from, to]);
        }
      }
    },
    nativeDropdown: true,
    selected: selectedDateRange
      ? dateRangeOptions[1].name
      : dateRangeOptions[0].name,
  };

  const dateRangeFilter: ListFilterProps | undefined = selectedDateRange
    ? {
        dateRange: selectedDateRange,
        update: updateDateRange,
        showTimeSelect: (opts && opts.showTimeSelect) ?? false,
      }
    : undefined;

  return { selectedDateRange, dateRangeFilter, dateRangeOptionFilter };
};

export default useDateRangeListFilter;
