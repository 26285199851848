import './salesoverview.css';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { injectMissingDays } from '../../../view/dashboard/Dashboard';
import { useUser } from '../../../contexts/auth/User';
import useDateTools from '../../../hooks/useDateTools';
import RevenueData from '../../../view/dashboard/types/RevenueData';
import SalesOverViewSubElement from './salesoverviewsubelement/SalesOverviewSubElement';
import SalesOverviewElement from './salesoverviewelement/SalesOverviewElement';
import { ReactComponent as IconIncome } from '../../../../assets/icon/income.svg';
import { ReactComponent as IconRefund } from '../../../../assets/icon/income_return.svg';
import { ReactComponent as IconBalance } from '../../../../assets/icon/income_save.svg';
import { ReactComponent as IconError } from '../../../../assets/icon/warning.svg';
import { ReactComponent as IconFlag } from '../../../../assets/icon/flag.svg';
import Hoverable from '../../../elements/hoverable/Hoverable';
import { HintBox } from '../../../elements/hint/Hint';

export type SalesOverViewMode = 'revenue' | 'orders';

interface SalesOverviewProps {
  height?: number;
  revenueData: RevenueData[];
  mode: SalesOverViewMode;
  selectedManufacturerId?: string | null;
  showPrognosis?: boolean;
}

const SalesOverview: React.FC<SalesOverviewProps> = ({
  height,
  revenueData,
  mode,
  selectedManufacturerId,
  showPrognosis,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.salesOverview',
  });
  const api = usePetCloudApi();
  const dashboardsApi = api.dashboardsApi();
  const errorHandler = useErrorHandler();
  const {
    getDateOnlyFromDate,
    dateToCSharpDateTimeOffset,
    getDayCountOfCurrentMonth,
  } = useDateTools();
  const { user } = useUser();

  const [sortedFixedRevenueData, setSortedFixedRevenueData] = useState<{
    thisMonth: RevenueData[];
    lastMonthToThisDay: RevenueData[];
    lastMonth: RevenueData[];
    twoMonthsAgo: RevenueData[];
  }>({
    thisMonth: [],
    lastMonthToThisDay: [],
    lastMonth: [],
    twoMonthsAgo: [],
  });

  useEffect(() => {
    getFixedData();
  }, [selectedManufacturerId]);

  // this is to display revenues like the past week or month regardless of what time range is currently selected by the user
  const getFixedData = () => {
    const date = new Date();
    const today = getDateOnlyFromDate(date) ?? 'error';
    const twoMonthsAgo = new Date(date.setMonth(date.getMonth() - 2));
    const beginningOfTwoMonthsAgo =
      twoMonthsAgo.getFullYear() +
      '-' +
      (twoMonthsAgo.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      '01';

    const manufacturerId =
      selectedManufacturerId && selectedManufacturerId !== 'all'
        ? selectedManufacturerId
        : undefined;
    const from = new Date(beginningOfTwoMonthsAgo);
    const to = new Date();

    dashboardsApi
      .dashboardsGetDailyRevenue(manufacturerId, beginningOfTwoMonthsAgo, today)
      .then((response) => {
        console.log(response);
        const revenue = injectMissingDays(
          response.data,
          {
            manufacturerId: null,
            totalRevenueNet: 0,
            totalRevenue: 0,
            totalSuccessOrders: 0,
            totalFailedOrders: 0,
            totalRefundAmount: 0,
            totalNumberOfRefunds: 0,
          },
          from,
          to
        );

        dashboardsApi
          .dashboardsGetDailyRefunds(
            manufacturerId,
            dateToCSharpDateTimeOffset(from),
            dateToCSharpDateTimeOffset(to)
          )
          .then((response) => {
            console.log(response);
            const result: RevenueData[] = revenue.map((rev) => {
              const refunds = response.data.find((x) => x.date === rev.date);
              return {
                ...rev,
                totalRefundAmount: refunds?.totalRefundAmount
                  ? refunds.totalRefundAmount * -1
                  : 0,
                totalNumberOfRefunds: refunds?.totalNumberOfRefunds ?? 0,
              };
            });
            setSortedFixedRevenueData(splitDataByMonths(result));
          })
          .catch((error) => {
            console.log(error);
            errorHandler.addError(error.response);
          });
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const splitDataByMonths = (dailyRevenues: RevenueData[]) => {
    const thisMonth: RevenueData[] = [];
    const lastMonthToThisDay: RevenueData[] = [];
    const lastMonth: RevenueData[] = [];
    const twoMonthsAgo: RevenueData[] = [];

    const date = new Date();
    const todayDate = new Date(date);
    const lastMonthDate = new Date(date.setMonth(date.getMonth() - 1));
    const twoMonthsAgoDate = new Date(date.setMonth(date.getMonth() - 1));

    const thisMonthString = (todayDate.getMonth() + 1)
      .toString()
      .padStart(2, '0');
    const lastMonthString = (lastMonthDate.getMonth() + 1)
      .toString()
      .padStart(2, '0');
    const twoMonthsAgoString = (twoMonthsAgoDate.getMonth() + 1)
      .toString()
      .padStart(2, '0');

    const currentDayOfMonth = todayDate.getDate();

    dailyRevenues.forEach((revenue) => {
      const revenueMonth = revenue.date.substring(5, 7);
      const revenueDay = parseInt(revenue.date.substring(8, 10));

      if (revenueMonth === thisMonthString) {
        thisMonth.push(revenue);
      } else if (revenueMonth === lastMonthString) {
        lastMonth.push(revenue);
        if (revenueDay <= currentDayOfMonth) {
          lastMonthToThisDay.push(revenue);
        }
      } else if (revenue.date.substring(5, 7) === twoMonthsAgoString) {
        twoMonthsAgo.push(revenue);
      }
    });

    return {
      thisMonth: thisMonth,
      lastMonthToThisDay: lastMonthToThisDay,
      lastMonth: lastMonth,
      twoMonthsAgo: twoMonthsAgo,
    };
  };

  const getTotal = (array: RevenueData[], propName: keyof RevenueData) => {
    let initialValue = 0;
    return array.reduce((prev, next) => {
      return prev + (next[propName] as number);
    }, initialValue);
  };

  const getAverage = (array: RevenueData[], propName: keyof RevenueData) => {
    const total = getTotal(array, propName);
    return parseFloat((total / revenueData.length).toFixed(2));
  };

  const getPrognosis = (array: RevenueData[], propName: keyof RevenueData) => {
    return getAverage(array, propName) * getDayCountOfCurrentMonth();
  };

  const totalIncomePropName =
    mode === 'revenue' ? 'totalRevenue' : 'totalSuccessOrders';
  const totalReturnPropName =
    mode === 'revenue' ? 'totalRefundAmount' : 'totalNumberOfRefunds';

  const totalRevenue = getTotal(revenueData, totalIncomePropName);
  const avgRevenue = getAverage(revenueData, totalIncomePropName);
  const totalRefunds = getTotal(revenueData, totalReturnPropName);
  const avgRefunds = getAverage(revenueData, totalReturnPropName);
  const totalFailed = getTotal(revenueData, 'totalFailedOrders');

  const refundRatio = parseFloat(
    ((totalRefunds / totalRevenue) * 100).toPrecision(2)
  );
  const failedRatio = parseFloat(
    ((totalFailed / (totalRevenue + totalFailed)) * 100).toPrecision(2)
  );

  return (
    <div className="salesOverview" style={{ height: height }}>
      <div className={'salesOverview-elements'}>
        <Hoverable
          onHoverNode={
            <HintBox paragraphs={[t(`${mode}.hints.income`)]} isToolTip />
          }
          helperClass={'salesOverviewElement'}
        >
          <SalesOverviewElement
            value={totalRevenue}
            mode={mode}
            averageValue={avgRevenue}
            icon={<IconIncome className={'salesOverviewSubElement-icon'} />}
          />
        </Hoverable>
        <Hoverable
          onHoverNode={
            <HintBox paragraphs={[t(`${mode}.hints.returns`)]} isToolTip />
          }
          helperClass={'salesOverviewElement'}
        >
          <SalesOverviewElement
            value={totalRefunds}
            mode={mode}
            averageValue={avgRefunds}
            icon={<IconRefund className={'salesOverviewSubElement-icon'} />}
            ratio={{ value: refundRatio, color: 'var(--color-inherited)' }}
          />
        </Hoverable>
        <Hoverable
          onHoverNode={
            <HintBox paragraphs={[t(`${mode}.hints.combined`)]} isToolTip />
          }
          helperClass={'salesOverviewElement'}
        >
          <SalesOverviewElement
            value={totalRevenue - totalRefunds}
            mode={mode}
            averageValue={avgRevenue - avgRefunds}
            icon={<IconBalance className={'salesOverviewSubElement-icon'} />}
          />
        </Hoverable>
        {mode === 'revenue' && showPrognosis ? (
          <Hoverable
            onHoverNode={
              <HintBox paragraphs={[t(`${mode}.hints.prognosis`)]} isToolTip />
            }
            helperClass={'salesOverviewElement'}
          >
            <SalesOverviewElement
              value={getPrognosis(revenueData, totalIncomePropName)}
              mode={mode}
              icon={<IconFlag className={'salesOverviewSubElement-icon'} />}
            />
          </Hoverable>
        ) : null}
        {user?.isProductOwner && mode === 'orders' ? (
          <Hoverable
            onHoverNode={
              <HintBox paragraphs={[t(`${mode}.hints.failed`)]} isToolTip />
            }
            helperClass={'salesOverviewElement'}
          >
            <SalesOverviewElement
              value={getTotal(revenueData, 'totalFailedOrders')}
              mode={mode}
              averageValue={getAverage(revenueData, 'totalFailedOrders')}
              icon={<IconError className={'salesOverviewSubElement-icon'} />}
              ratio={{ value: failedRatio, color: 'var(--color-danger)' }}
            />
          </Hoverable>
        ) : null}
      </div>
      <div className={'salesOverview-elements-sub'}>
        {/*<SalesOverViewSubElement*/}
        {/*  title={t('today')}*/}
        {/*  value={getToday() ?? 0}*/}
        {/*  compareValue={getPastDay(1) ?? 0}*/}
        {/*  mode={mode}*/}
        {/*/>*/}
        <Hoverable
          onHoverNode={<HintBox paragraphs={[t('thisMonth.hint')]} isToolTip />}
          helperClass={'salesOverviewSubElement'}
        >
          <SalesOverViewSubElement
            title={t('thisMonth.title')}
            value={
              getTotal(sortedFixedRevenueData.thisMonth, totalIncomePropName) ??
              0
            }
            compareValue={
              getTotal(
                sortedFixedRevenueData.lastMonthToThisDay,
                totalIncomePropName
              ) ?? 0
            }
            mode={mode}
          />
        </Hoverable>
        <Hoverable
          onHoverNode={<HintBox paragraphs={[t('lastMonth.hint')]} isToolTip />}
          helperClass={'salesOverviewSubElement'}
        >
          <SalesOverViewSubElement
            title={t('lastMonth.title')}
            value={
              getTotal(sortedFixedRevenueData.lastMonth, totalIncomePropName) ??
              0
            }
            compareValue={
              getTotal(
                sortedFixedRevenueData.twoMonthsAgo,
                totalIncomePropName
              ) ?? 0
            }
            mode={mode}
          />
        </Hoverable>
      </div>
    </div>
  );
};

export default SalesOverview;

// <div className="salesOverview-spotlights">
//   <div className="salesOverview-spotlight">
//     <div className="salesOverview-spotlight-value">
//       <div
//         style={
//           mode === 'orders' && user?.isProductOwner
//             ? { color: 'var(--color-success)' }
//             : undefined
//         }
//       >
//         {getTotal(revenueData) + (mode === 'revenue' ? ' €' : '')}
//       </div>
//       {mode === 'orders' && user?.isProductOwner ? (
//         <>
//           <span>/</span>
//           <div style={{ color: 'var(--color-danger)' }}>
//             {getTotal(revenueData, true)}
//           </div>
//         </>
//       ) : null}
//     </div>
//     <div className="salesOverview-spotlight-title">
//       {t('total')}
//     </div>
//   </div>
//   <div className="salesOverview-spotlight">
//     <div className="salesOverview-spotlight-value">
//       {getAverage(revenueData) + (mode === 'revenue' ? ' €' : '')}
//     </div>
//     <div className="salesOverview-spotlight-title">
//       {t('avgPerDay')}
//     </div>
//   </div>
// </div>
