import {
  ComplexRequiredFieldConfig,
  RequiredFieldValidationMap,
} from '../../jsontable/tablecompletionbar/TableCompletionBar';
import { BulkEditorProduct } from '../../../view/products/BulkEditor';
import React from 'react';
import { ReactComponent as IconRichText } from '../../../../assets/icon/jsontable/a.svg';
import { ReactComponent as IconTranslation } from '../../../../assets/icon/jsontable/translate.svg';
import { ReactComponent as IconText } from '../../../../assets/icon/jsontable/textedit.svg';
import { ReactComponent as IconNumber } from '../../../../assets/icon/jsontable/123.svg';
import { ReactComponent as IconBrand } from '../../../../assets/icon/jsontable/brand.svg';
import { ReactComponent as IconCategories } from '../../../../assets/icon/jsontable/categories.svg';
import { ReactComponent as IconCheckBox } from '../../../../assets/icon/jsontable/checkbox.svg';
import { ReactComponent as IconCoins } from '../../../../assets/icon/jsontable/coins.svg';
import { ReactComponent as IconChart } from '../../../../assets/icon/jsontable/chart.svg';
import { ReactComponent as IconInfo } from '../../../../assets/icon/jsontable/information.svg';
import { ReactComponent as IconLock } from '../../../../assets/icon/jsontable/lock.svg';
import { ReactComponent as IconMedia } from '../../../../assets/icon/jsontable/media.svg';
import { ReactComponent as IconOption } from '../../../../assets/icon/jsontable/option.svg';
import { ReactComponent as IconProperty } from '../../../../assets/icon/jsontable/property.svg';
import { ReactComponent as IconStocks } from '../../../../assets/icon/jsontable/stocks.svg';
import { ReactComponent as IconTime } from '../../../../assets/icon/jsontable/time.svg';
import { ReactComponent as IconPaw } from '../../../../assets/icon/jsontable/paw.svg';
import { TranslatedTypeResponseOfString } from '../../../api/petcloudapi/api';
import { availableLanguages } from '../../../../locate/languageUtils';

const iconText = (
  <IconText
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);
const iconTranslation = (
  <IconTranslation
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);
const iconRichText = (
  <IconRichText
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);
const iconNumber = (
  <IconNumber
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);
const iconBrand = (
  <IconBrand
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);
const iconCategories = (
  <IconCategories
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);
const iconCheckBox = (
  <IconCheckBox
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);
const iconCoins = (
  <IconCoins
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);
const iconChart = (
  <IconChart
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);
const iconInfo = (
  <IconInfo
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);
const iconLock = (
  <IconLock
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);
const iconMedia = (
  <IconMedia
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);
const iconOption = (
  <IconOption
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);
const iconProperty = (
  <IconProperty
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);
const iconStocks = (
  <IconStocks
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);
const iconTime = (
  <IconTime
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);
const iconPaw = (
  <IconPaw
    fill={'var(--color-text_secondary)'}
    className={'jsontable-header-cell-icon'}
  />
);

export const columnIcons: { [p: string]: JSX.Element } = {
  name: iconTranslation,
  seoProductTitle: iconTranslation,
  ean: iconText,
  animalSpecies: iconPaw,
  manufacturerProductNumber: iconText,
  productNumber: iconLock,
  description: iconRichText,
  productLine: iconTranslation,
  marketingText: iconRichText,
  productGroupId: iconOption,
  categories: iconCategories,
  variantOptions: iconProperty,
  properties: iconProperty,
  restockTimeDays: iconNumber,
  isCloseout: iconCheckBox,
  taxTypeIdentifier: iconOption,
  purchaseSteps: iconNumber,
  minPurchase: iconNumber,
  maxPurchase: iconNumber,
  purchaseUnit: iconNumber,
  referenceUnit: iconNumber,
  weight: iconNumber,
  width: iconNumber,
  length: iconNumber,
  height: iconNumber,
  productUnitId: iconOption,
  packUnit: iconTranslation,
  packUnitPlural: iconTranslation,
  deliveryTimes: iconTime,
  isShippingFree: iconCheckBox,
  isBatchControlled: iconCheckBox,
  isBestBeforeControlled: iconCheckBox,
  isDangerousGoods: iconCheckBox,
  productPrices: iconCoins,
  productStocks: iconStocks,
  productAssets: iconMedia,
  additionalInformation: iconInfo,
  analyticConstituents: iconChart,
  brandId: iconBrand,
  id: iconLock,
};

// overrides the default width of 200 for columns
export const defaultColumnWidths: { [key: string]: number } = {
  isCloseout: 86,
  isShippingFree: 120,
  name: 400,
  description: 400,
  productPrices: 300,
};

export const defaultHeaderOrder = [
  'ean',
  'manufacturerProductNumber',
  'brandId',
  'name',
  'seoProductTitle',
  'description',
  'productLine',
  'animalSpecies',
  'productGroupId',
  'variantOptions',
  'properties',
  'categories',
  'mainCategoryId',
  'taxTypeIdentifier',
  'productPrices',
  'productUnitId',
  'purchaseUnit',
  'referenceUnit',
  'purchaseSteps',
  'minPurchase',
  'maxPurchase',
  'packUnit',
  'packUnitPlural',
  'weight',
  'width',
  'length',
  'height',
  'productStocks',
  'restockTimeDays',
  'deliveryTimes',
  'isCloseout',
  'isBatchControlled',
  'isBestBeforeControlled',
  'isDangerousGoods',
  'productAssets',
  'additionalInformation',
  'analyticConstituents',
  'productNumber',
  'id',
];

export const disabledColumns = ['productNumber', 'id'];

// columns with keys in this list will have a visual asterisk
export const requiredColumns = [
  'ean',
  'name',
  'brandId',
  'productGroupId',
  'animalSpecies',
  'categories',
  'mainCategoryId',
  'description',
  'taxTypeIdentifier',
  'productUnitId',
  'purchaseUnit',
  'referenceUnit',
  'variantOptions',
  'productStocks',
  'productPrices',
  'properties',
  'productAssets',
];

// used for validating the presence of content for cells in that column
export const requiredFieldConfigs: ComplexRequiredFieldConfig[] = [
  // parent product with variants
  {
    conditionFunc: (item: BulkEditorProduct) =>
      !item.parentId && !!item.hasVariants,
    requiredFields: [
      'name',
      'brandId',
      'productGroupId',
      'animalSpecies',
      'categories',
      'description',
      'taxTypeIdentifier',
      'productUnitId',
      'productAssets',
      'productPrices',
      'purchaseUnit',
      'referenceUnit',
    ],
  },
  // parent product without variants
  {
    conditionFunc: (item: BulkEditorProduct) =>
      !item.parentId && !item.hasVariants,
    requiredFields: [
      'ean',
      'name',
      'brandId',
      'productGroupId',
      'animalSpecies',
      'categories',
      'description',
      'taxTypeIdentifier',
      'productUnitId',
      'productStocks',
      'purchaseUnit',
      'referenceUnit',
      'variantOptions',
      'productPrices',
      'productAssets',
    ],
  },
  // variant
  {
    conditionFunc: (item: BulkEditorProduct) =>
      !!item.parentId && !item.hasVariants,
    requiredFields: [
      'ean',
      'name',
      'productGroupId',
      'animalSpecies',
      'taxTypeIdentifier',
      'productUnitId',
      'brandId',
      'productStocks',
      'variantOptions',
      'categories',
      'productPrices',
    ],
  },
];

const isNotEmptyArray = (v: any) => {
  return v.length > 0;
};

const hasAtLeastOneTranslation = (t: TranslatedTypeResponseOfString) => {
  return availableLanguages.some((lang) => {
    const v = t[lang];
    return v !== undefined && v !== null && v !== '';
  });
};

// cells / columns specified above by default are only getting null-checked,
// here the validation functions can be further specified
export const requiredFieldValidationMap: RequiredFieldValidationMap = {
  animalSpecies: isNotEmptyArray,
  categories: isNotEmptyArray,
  name: hasAtLeastOneTranslation,
  description: hasAtLeastOneTranslation,
  variantOptions: isNotEmptyArray,
};

export const getBlackListedColumns = (isDropShipper?: boolean) => {
  const list = [
    'mode',
    'parent',
    'mainVariantId',
    'releaseDate',
    'children',
    'propertyGroups',
    'productUnit',
    'coverId',
    'cover',
    'tags',
    'productGroup',
    'hasVariants',
    'parentId',
    'state',
    'manufacturerId',
    'activeProductVersionId',
    'brand',
    'createdAt',
    'updatedAt',
    'errors',
    'marketingText',
    'isShippingFree',
  ];
  if (isDropShipper) {
    list.concat(['length', 'width', 'height', 'weight']);
  }
  return list;
};
