import './integrationloglist.css';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useUser } from '../../../contexts/auth/User';
import { useCallback, useEffect, useState } from 'react';
import {
  IntegrationIdentifierType,
  IntegrationSubModuleLogDocument,
  IntegrationSubModuleType,
  LogLevel,
} from '../../../api/shopifyapi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import useAnimalSpeciesListFilter from '../../../features/list/listcontrols/listfilter/hooks/useAnimalSpeciesListFilter';
import List from '../../../features/list/List';
import { ReactComponent as IconInformation } from '../../../../assets/icon/information.svg';
import { ReactComponent as IconDebug } from '../../../../assets/icon/bug.svg';
import { ReactComponent as IconTrace } from '../../../../assets/icon/target.svg';
import { ReactComponent as IconWarning } from '../../../../assets/icon/warning.svg';
import { ReactComponent as IconCritical } from '../../../../assets/icon/fire.svg';
import { ReactComponent as IconQuestion } from '../../../../assets/icon/question.svg';
import { ReactComponent as IconShopify } from '../../../../assets/icon/integrations/shopify.svg';
import { ReactComponent as IconShopware } from '../../../../assets/icon/integrations/shopware.svg';
import useListRenderObjects from '../../../hooks/list/useListRenderObjects';
import useManufacturerListFilter from '../../../features/list/listcontrols/listfilter/hooks/useManufacturerListFilter';
import useDateRangeListFilter from '../../../features/list/listcontrols/listfilter/hooks/useDateRangeListFilter';
import useLimitListFilter from '../../../features/list/listcontrols/listfilter/hooks/useLimitListFilter';

interface IntegrationLogListProps {}

const IntegrationLogList: React.FC<IntegrationLogListProps> = ({}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integrationLogs',
  });
  const api = usePetCloudApi();
  const integrationLoggingApi = api.shopifyIntegrationLoggingApi();
  const integrationsApi = api.integrationsApi();
  const { user } = useUser();
  const errorHandler = useErrorHandler();

  const integrationSubModuleTypeOptions = [
    t('allIntegrationSubModuleTypes'),
    'Base',
    'OrderExport',
    'InvoiceDocumentExport',
    'CreditNoteDocumentExport',
    'OrderCancellationInvoiceExport',
    'CustomerExport',
    'OrderDeliveryInformationExport',
    'ProductImport',
    'InvoiceDocumentImport',
    'ProductStockImport',
    'OrderDeliveryInformationImport',
    'WebhookRegistrar',
  ];

  const { selectedManufacturerId, manufacturerFilter } =
    useManufacturerListFilter({ noAllManufacturers: true });
  const { selectedDateRange, dateRangeOptionFilter, dateRangeFilter } =
    useDateRangeListFilter({ showTimeSelect: true });
  const { selectedLimit, limitFilter } = useLimitListFilter({
    defaultLimit: 50,
  });

  const { renderManufacturer } = useListRenderObjects();

  const [logs, setLogs] = useState<IntegrationSubModuleLogDocument[] | null>(
    null
  );
  const [integrationOptions, setIntegrationOptions] = useState<
    string[] | null | undefined
  >(null);
  const [selectedIntegrationIdentifier, setSelectedIntegrationIdentifier] =
    useState<IntegrationIdentifierType>(IntegrationIdentifierType.Shopify);
  const [
    selectedIntegrationSubModuleType,
    setSelectedIntegrationSubModuleType,
  ] = useState<IntegrationSubModuleType | string>(
    t('allIntegrationSubModuleTypes')
  );

  useEffect(() => {
    getLogs();
  }, [
    selectedManufacturerId,
    selectedDateRange,
    selectedLimit,
    selectedIntegrationIdentifier,
    selectedIntegrationSubModuleType,
  ]);

  const getManufacturerId = () => {
    if (user?.isProductOwner) {
      return selectedManufacturerId !== 'all'
        ? selectedManufacturerId
        : undefined;
    } else {
      return user?.manufacturerId ?? undefined;
    }
  };

  const getLogs = () => {
    const mId = getManufacturerId();
    if (mId) {
      setLogs(null);
      integrationLoggingApi
        .apiLogsGet(
          selectedIntegrationIdentifier,
          selectedIntegrationSubModuleType !== t('allIntegrationSubModuleTypes')
            ? (selectedIntegrationSubModuleType as IntegrationSubModuleType)
            : undefined,
          getDate('from'),
          getDate('to'),
          selectedLimit,
          getManufacturerId()
        )
        .then((response) => {
          console.log(response);
          setLogs(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    } else {
      setLogs([]);
    }
  };

  useEffect(() => {
    getIntegrationOptions();
  }, []);

  const getIntegrationOptions = () => {
    integrationsApi
      .integrationsGetIntegrations()
      .then((response) => {
        console.log(response);
        setIntegrationOptions(
          response.data.map((integration) => integration.identifier)
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getDate = (type: 'from' | 'to') => {
    if (selectedDateRange) {
      const fromDate = selectedDateRange[0];
      const toDate = selectedDateRange[1];
      if (type === 'from') {
        return fromDate;
      } else {
        return toDate;
      }
    } else {
      return undefined;
    }
  };

  const renderLevel = useCallback((level: LogLevel) => {
    return (
      <div
        className={`integrationLogList-level integrationLogList-level__${level
          .toString()
          .toLowerCase()}`}
      >
        {logLevelIcon[level]}
        {level}
      </div>
    );
  }, []);

  const renderIntegrationIdentifier = useCallback(
    (integrationIdentifier: LogLevel) => {
      return (
        <div className={'integrationLogList-integration'}>
          {integrationIcon[integrationIdentifier]}
          {integrationIdentifier}
        </div>
      );
    },
    []
  );

  if (integrationOptions !== null) {
    return (
      <div className={'integrationLogList'}>
        <List
          items={logs}
          listControls={{
            filters: [
              manufacturerFilter,
              integrationOptions && integrationOptions.length > 1
                ? {
                    options: integrationOptions,
                    update: (v) => {
                      setSelectedIntegrationIdentifier(
                        v as IntegrationIdentifierType
                      );
                    },
                    selected: selectedIntegrationIdentifier,
                  }
                : undefined,
              {
                options: integrationSubModuleTypeOptions,
                update: (v) => {
                  setSelectedIntegrationSubModuleType(v);
                },
                selected: selectedIntegrationSubModuleType,
              },
              dateRangeOptionFilter,
              dateRangeFilter,
              limitFilter,
            ],
          }}
          renderObjects={[
            {
              key: 'level',
              renderMethod: renderLevel,
            },
            {
              key: 'integrationIdentifier',
              renderMethod: renderIntegrationIdentifier,
            },
            {
              key: 'manufacturerId',
              renderMethod: renderManufacturer,
            },
          ]}
          dateStrings={['createdAt']}
          dateStringsFormat={[
            'weekday',
            'day',
            'month',
            'year',
            'time',
            'seconds',
          ]}
          ignore={['updatedAt', 'manufacturerId', 'id']}
          monoSpaceStrings={['type']}
          isShowingIndex
          tableHeadContrast
          adjustHeightToViewport
          adjustHeightToViewportOffset={120}
          pageItemCountOptions={[25, 50, 100, 500]}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default IntegrationLogList;

const logLevelIcon: { [key: string]: JSX.Element } = {
  Information: <IconInformation className={'integrationLogList-level-icon'} />,
  Debug: <IconDebug className={'integrationLogList-level-icon'} />,
  Trace: <IconTrace className={'integrationLogList-level-icon'} />,
  Warning: <IconWarning className={'integrationLogList-level-icon'} />,
  Error: <IconWarning className={'integrationLogList-level-icon'} />,
  Critical: <IconCritical className={'integrationLogList-level-icon'} />,
  None: <IconQuestion className={'integrationLogList-level-icon'} />,
};

const integrationIcon: { [key: string]: JSX.Element } = {
  Shopify: <IconShopify className={'integrationLogList-integration-icon'} />,
  Shopware: <IconShopware className={'integrationLogList-integration-icon'} />,
};
